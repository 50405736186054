import { ReactElement, ReactNode } from 'react'
import { Center, CenterProps, Group, Loader, MantineSize, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

export type LoadingMessageProps = {
  message?: ReactNode,
  size?: MantineSize
}

function LoadingMessage (props: Omit<CenterProps, 'children'> & LoadingMessageProps): ReactElement {
  const {
    message,
    size = 'sm',
    ...others
  } = props

  return (
    <Center {...others}>
      <Group gap="xs">
        <Loader size={size} />
        <Text c="dimmed">
          {message ?? (
            <FormattedMessage
              id="loading"
              defaultMessage="Chargement"
            />
          )}
        </Text>
      </Group>
    </Center>
  )
}

export default LoadingMessage
